export const DATA = [
  {
    id: 6,
    year: '2021',
    title: '2021 Meet the future',
    img: ['sucai/top/2021Meet the Future.jpg'],
    titleimg: 'sucai/top/2021.png',
    desc: `2021 ECI Awards launch ceremony and global press conference was held in Shanghai on March 27. With the successful experience of previous years and the new activity plan for the New Year, ECI is back in Shanghai, China. This year, the ECI Awards will be upgraded to include three sections: ArTech, MarTech and HiTech and the innovation in education. At the press conference, the heads of each section made a statement on the work plan. Let us look forward to the successful progress of all the activities this year.`,
  },
  {
    id: 5,
    year: '2020',
    title: '2020 Future is Now',
    img: ['sucai/top/Future-is-now-(2).jpg', 'top/Future is now(1).png'],
    titleimg: 'sucai/top/2020.png',
    desc: '',
  },
  {
    id: 4,
    year: '2019',
    title: '2019 Share the Future',
    img: ['sucai/top/2019 Share the Future.jpg'],
    titleimg: 'sucai/top/2019.png',
    desc: `ECI Festival press conference has been held on September 12, 2019 in Shanghai IPMALL.<br>
    This year's ECI Festival will take "innovation and unlimited opportunities" as the theme and will be jointly organized by the ECI Awards Organizing Committee and IP MALL.
    <br>The International Entrepreneurs, Creatives, & Innovators Association and China Business Advertising Association Business Innovation Committee will be the advisory unit. The special co-organizer of the ECI Festival will be conducted by Dot Connecter. Meanwhile, IPMALL media
    and IPMALL exhibition will take the responsibility as the joint organizer of the 2019 ECI Festival. 
    <br><br>
    ECI Festival 2019 is jointly organized by the ECI Awards Organizing Committee and IP MALL. It is co-sponsored by 23 ECI  countries and regions as members to build the most influential resource aggregation platform among the global innovation industry chains.
    <br>
    At the press conference, the chairman of the IECIA, Su Xiong, said in his opening speech: "China has become a hot word in the world in recent years. The ‘new four inventions’ are all causing waves in the world. All these are inseparable from innovation, and China's innovation has also become a new source of progress in the whole sphere, which has a more prominent impact on the world's innovation field.”
    <br>
    In addition, Dr. Owen Jia, Executive Chairman of ECI Awards, gave a comprehensive explanation of the content structure and direction of the 2019 ECI Festival. He said that in this festival, we will put forward three highlights under the leadership of "Innovation Values Driven, Innovation Mission Driven".
    <br>
    Firstly, in this year’s ECI Festival, we will launch the Council and issue the ECI Global Innovation Declaration with the representatives of 23 member countries around the world. 
    <br>
    Secondly, ECI believes that education is the only way to hatch a new generation of innovation. We need generations of innovative talents to change the world and create the future. Therefore, we have established the forum of chief innovation officers of ECI Innovation Academy, and launched a global innovation tour to achieve the next generation of chief innovation officers with the innovative curriculum system and scenario case teaching method of "science and technology innovation + capital operation + brand aesthetics + creative communication + business management". 
    <br>
    Thirdly, this year ECI will establish China's independent brand innovation forum will be guided by innovation, and put forward the path of innovation and development in line with China's own brand, and carry forward the spirit of self brand innovation and commendation success model.China's independent brand innovation forum needs to innovate in promoting digital transformation of Chinese traditional enterprises, promoting transformation of Chinese products to Chinese brands, and innovating and shaping the good image of Chinese independent brands.
    <br>
    Last but not the least, on behalf of the ECI organizing committee at the press conference, the famous Chinese choreographer, Miss Tian Fang, officially launched the “ECI TIMES“ series of innovative personage legendary documentaries. ECI TALK will focus on the annual innovators to launch IP planning and production of character interviews, reality shows, feature films, movies and TV dramas. We also invite innovators of the year to share innovative life and ideas with the younger generation.
    <br><br>
    
    2019 ECI FESTIVAL - SHANGHAI
    <br>Date: 11 December
    <br>Location: SHANGHAI
    <br><br>
    This year, ECI will provide more systematic and professional services to the global digital business with IP MALL. This will enable more cities, industries, enterprises and brands to create more opportunities and values for innovation, work together to create a ECI innovation industry ecosystem, bring innovation into life, and promote China's innovation to influence the world. Let the world share "China's opportunity".`,
  },
  {
    id: 3,
    year: '2018',
    title: '2018 Next',
    img: ['sucai/top/2018-Next.jpg'],
    titleimg: 'sucai/top/2018.png',
    desc: `First edition of ECI Festival North America is slated for August 3&4. This annual convention will be mainly focused on three topics: venture capital financing, technology and marketing. Another grand event organized by ECI Awards that promotes business innovation, only we will see you at Silicon Valley this time - those who have been to the ECI Festival Beijing 2016 know what we're gonna present!`,
  },
  {
    id: 2,
    year: '2017',
    title: '2017 Innovation unlimited',
    img: [
      'sucai/top/2017 Innovation unlimited(1).jpg',
      'sucai/top/2017 Innovation unlimited(2).png',
      'sucai/top/2017 Innovation unlimited.jpg',
    ],
    titleimg: 'sucai/top/2017.png',
    desc: `2017 ECI Festival North America
    2017 ECI Festival North America was held from August 3, 20/17 to August 11, 2017. Several industry experts and local government officials attended the ribbon cutting ceremony.
    
    <br>
    
    
    ECI delegation visited Facebook on August 4, 2017. 
    
    
    <br>
    
    ECI Innovation Workshop took place on the campus of Stanford University on August 5, 2017. Professor Jerry I. Porras was invited to make a presentation. 
    
    <br>
    
    
    ECI delegation visited Google's headquarter and had a better understanding of the R&D direction of AI, especially speech recognition technology. “ Cloud + Machine Learning + Smart phone = Magic is Normal”
    
    <br>
    
    
    ECI delegation visited California State Capitol in Sacramento and were received as VIP guests by California Senator Jim Nielsen. 
    <br>
    
    
    
    ECI delegation visited Alta Devices, owned by Hanergy—winner of 2017 ECI Outstanding Organizations in China US Business Innovation Award and met with its executives.
    
    <br>
    
    
    Intel's museum was the last stop of the same-day visit.`,
  },
  {
    id: 1,
    year: '2016',
    title: '2016 Define the future',
    img: [
      'sucai/top/2016 Define the Future.jpg',
      'sucai/top/2016 Define the Future.png',
    ],
    titleimg: 'sucai/top/2016.png',
    desc: '',
  },
];
